import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtrar'
})

export class FiltrarPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    const resultado = [];
    //Recorre el valor que le esta pasando
    for(const post of value){
      //verifica si encuentra el valor
      if(post.item.indexOf(arg) > -1 ){
        //Lo agrega
        resultado.push(post);
      }else if(post.clave.indexOf(arg) > -1 ){
        //Lo agrega
        resultado.push(post);
      }else if(post.fecha.indexOf(arg) > -1 ){
        //Lo agrega
        resultado.push(post);
      }
    }
    return resultado;
  }

}
