import { Component, OnInit } from '@angular/core';
import {ConvenioService } from '../../service/app.service';
import { ConnectableObservable } from 'rxjs';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css'],
  providers:[ConvenioService]
})
export class BodyComponent implements OnInit {

  constructor(public _service:ConvenioService) { }

  ngOnInit() {
  }

}
