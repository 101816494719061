import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ConvenioService } from '../../service/app.service';
import { Lista } from '../../models/lista';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  providers:[ConvenioService]
})
export class ListadoComponent implements OnInit {
  public registros=[];
  public nombreArchivo:'';
  public lista:Lista;
  public invalido:boolean;
  pageNum:number=1;
  filterPost='';

  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _service:ConvenioService
  ) {
    this.invalido=false;
    this.verListado();
    this.lista = new Lista(1,'','','','',0);
  }

  ngOnInit() {
    this.sesion();
  }

  //VERIFICA QUE EXISTA LA SESSION
  sesion(){
    if(!localStorage.getItem('sesion')){
      this._router.navigate(['login']);
    }
  }

  //Guardamos la lista
  submit(){
    this.lista.archivo = this.nombreArchivo;
    this._service.insertListaPrecios(this.lista).subscribe(
      result=>{
        console.log(result);
        if(result['code'] == 200){
          Swal.fire(result['message'],'','success');
          this._router.navigate(['inicio']);
        }else{
          Swal.fire(result['message'],'','error');
        }
      },
      error=>{
        console.log("Upps! algo fallo");
      }
    )
  }
  //Asigna nombre del archivo
  getRuta(files:any){
    this._service.guardarArchivo(files[0]).subscribe(
      result=>{
        if(result['name'] == null){
          this.invalido = true;
          console.log("archivo incorrecto");
        }else{
          this.invalido = false;
          this.nombreArchivo = result['name'];
          console.log("archivo correcto");
        }
      },
      error=>{
        console.log("Algo salio mal");
      }
    )
  }

  verListado(){
    this._service.getListado().subscribe(
      result=>{
        this.registros = <any>result
      },
      error=>{
        console.log("Revisar, algo fallo");
      }
    )
  }

}
