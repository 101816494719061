export class Lista{

  constructor(
    public id:number,
    public fechaInicio:string,
    public fechaFin:string,
    public archivo:string,
    public estatus:string,
    public unidad:number
  ){
  }

}
