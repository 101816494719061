import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, Route} from '@angular/router';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html'
})
export class InicioComponent implements OnInit {

  constructor(private _router:Router) { }

  ngOnInit() {
    this.sesion();
  }
  //VERIFICA QUE EXISTA LA SESSION
  sesion(){
    if(!localStorage.getItem('sesion')){
      this._router.navigate(['login']);
    }
  }

}
