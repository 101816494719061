import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ConvenioService } from '../../service/app.service';

@Component({
  selector: 'app-head',
  templateUrl: './head.component.html',
  styleUrls: ['./head.component.css'],
  providers:[ConvenioService]
})
export class HeadComponent implements OnInit {

  constructor(private _router:Router,public _service:ConvenioService) { }

  ngOnInit() {
  }

  logout(){
    //Cierra las sessiones
    localStorage.removeItem('sesion');
    localStorage.removeItem('datos');
    localStorage.removeItem('totales');
    this._router.navigate(['login']);
  }

}
