import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ConvenioService } from '../../service/app.service';
declare var $;

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  providers:[ConvenioService]
})
export class CatalogoComponent implements OnInit {

  public elementos:Array<any>;
  public totales:Array<any>;
  public unidad:Array<any>;
  public activar:boolean;
  pageNum:number=1;
  numselect:number;
  //Filtrado de busqueda
  filtrando = '';

  //datatables


  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _service:ConvenioService
  ) {
    this.activar = false;
    //Obtiene los datos en cada una de las variables locales
    this.totales =  JSON.parse( localStorage.getItem('totales') );
    this.elementos = JSON.parse( localStorage.getItem('datos') );
    this.unidad = JSON.parse(localStorage.getItem('unidad'));
  }

  ngOnInit() {
    this.numselect = 1;
    console.log(this.elementos);
  }

  verificaSession(){
    if(!localStorage.getItem('sesion')){
      this._router.navigate(['login']);
    }
  }

  actualizar(clave,event){
    this.activar=true;
    this._service.updateItem(clave,event.target.value).subscribe(
      result=>{
        console.log(result);
      },
      error=>{
        console.log("Ups!! algo salio mal");
      }
    )
  }

}
