import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ConvenioService } from './service/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers:[ConvenioService]
})
export class AppComponent {
  public sesion:any;
  public identity:boolean;
  title = 'convenios';

  constructor(
    private _router:Router,
    public _service:ConvenioService
  ){
  }

  ngOnInit(){
  //  this.identity =  this._service.Auth();
  }



}
