import { Component, OnInit,ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ConvenioService } from '../../service/app.service';
import { Subject } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-consulta',
  templateUrl: './consulta.component.html',
  providers:[ConvenioService]
})
export class ConsultaComponent implements OnInit {
  public dataT:any;
 public listaprovedores = [];
 public listaPrecios = [];
 public cargando:boolean;
 //Numero de paginas
 pageNum:number=1;
 buscar ='';
 buscarpor = '';

  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _service:ConvenioService
  ) {
    this.proveedores();
  }

  ngOnInit():void {
    this.sesion();
    this.lista();
    this.cargando = true;

  }
  //Verifica un logeo
  sesion(){
    if(!localStorage.getItem('sesion')){
      this._router.navigate(['login']);
    }
  }

  //Lista de precios
  lista(){
    this._service.getListaPrecios().subscribe(
      result=>{
        this.listaPrecios = <any>result['resultado']
      },
      errro=>{
        console.log("hay un error,favor de verificar");
      }
    )
  }

  //Lista de provedores
  proveedores(){
    this._service.getProveedores().subscribe(
      result=>{
        this.listaprovedores = <any>result['respuesta'];
        this.cargando=false;
      },
      errro=>{
        console.log("hay un error,favor de verificar");
      }
    )
  }

}
