import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { ConvenioService } from '../../service/app.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  providers:[ConvenioService]
})
export class HomeComponent implements OnInit {
  OpcionSeleccionada:string = '0';
  archivo:string='';
  verSeleccion:string       = '';
  elementos=[];
  public listaprovedores = [];
  public ver:boolean;
  public formato:boolean;
  public activar:boolean;
  public cargando:boolean;
  public url:any;

  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _service:ConvenioService
  ){
    this.ver = false;
    this.formato = true;
    this.activar = false;
    this.cargando =  false;
  }

  ngOnInit() {
    this.sesion();
    this.provedores();

  }
  //VERIFICA QUE EXISTA LA SESSION
  sesion(){
    if(!localStorage.getItem('sesion')){
      this._router.navigate(['login']);
    }
  }

  //Lista de proveedores
  provedores(){
    this._service.getProveedores().subscribe(
      result=>{
        for(var i = 0; i < result['respuesta'].length; i++){
          this.listaprovedores.push(result['respuesta'][i]);
        }
      },
      errro=>{
        console.log("hay un error,favor de verificar");
      }
    )
  }

  capturar(){
    this.ver = true;
    this.verSeleccion = this.OpcionSeleccionada;

    this._service.getStatusPorId(this.verSeleccion).subscribe(
      result=>{
        //Guadamos los datos en una variable local
        localStorage.setItem('unidad',JSON.stringify(result));
      },
      error=>{
        console.log("Upps! Algo fallo");
      }
    )
  }

  //CARGA LOS DATOS LOCALMENTE DESCOMPRIMIDOS DEL ARCHIVO
  cargar(files:any){
    this._service.cargarArchivo(files[0]).subscribe(
      response=>{
        if(response['code'] != 200){
          this.formato= false;
        }else{
          this.formato = true;
          this.activar=true;
          //Descomprime la cadena en un array
          var cadena    = response['ruta'].split('||');
          //Recorre cada uno de los elementos
          for(var i = 0; i < cadena.length; i++){
            this.elementos.push(cadena[i]);
          }
        }

      },
      error=>{
        console.log("hay un error,favor de corregir");
      }
    )
  }

  //SUBE CADA UNO DE LOS ELEMENTOS A LA DB
  submit(){
    this.cargando = true;
    this._service.cargar_a_bd(this.elementos).subscribe(
      result=>{
        localStorage.setItem('datos',JSON.stringify( result['result']) );
        localStorage.setItem('totales',JSON.stringify( result['totales']));
        this._router.navigate(['catalogo']);
      },
      er=>{
        this.cargando =  false;
        console.log("Valio madres esto");
      }
    )
  }

}
