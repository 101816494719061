 import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { routing, appRoutingProviders } from './app.routing';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import * as $ from "jquery";


import { AppComponent } from './app.component';
import { LoginComponent } from './componentes/login/login.component';
import { HomeComponent } from './componentes/home/home.component';
import { CatalogoComponent } from './componentes/catalogo/catalogo.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { ListadoComponent } from './componentes/listado/listado.component';
import { ConsultaComponent } from './componentes/consulta/consulta.component';
import { HeadComponent } from './Estructura/head/head.component';
import { BodyComponent } from './Estructura/body/body.component';
import { FiltroPipe } from './pipes/filtro.pipe';
import { FiltrarPipe } from './pipes/filtrar.pipe';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    CatalogoComponent,
    InicioComponent,
    ListadoComponent,
    ConsultaComponent,
    HeadComponent,
    BodyComponent,
    FiltroPipe,
    FiltrarPipe

  ],
  imports: [
    BrowserModule,
    routing,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    DataTablesModule
  ],
  providers: [
    appRoutingProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
