import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params} from '@angular/router';
import { Usuario } from '../../models/usuario';
import { ConvenioService } from '../../service/app.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers:[ConvenioService]
})
export class LoginComponent implements OnInit {
  public user:Usuario;
  public fallo:boolean;
  public cargando:boolean;

  constructor(
    private _route:ActivatedRoute,
    private _router:Router,
    private _service:ConvenioService
  ) {
    this.user = new Usuario(1,'','',1,'','','','','','');
    this.fallo = false;
    this.cargando =  false;
  }

  ngOnInit() {
  }

  onSubmit(){
    this.cargando = true;
    this._service.login(this.user).subscribe(
      response=>{
        if(response['data'].length > 0){
          // console.log(response['data']);
          localStorage.setItem('sesion','si');
          this._router.navigate(['/inicio']);
        }else{
          this.cargando = false;
          this.fallo    = true;
        }
      },
      error=>{
        console.log(error);
      }

    )
  }


}
