
export class Usuario{
  constructor(
    public id:number,
    public nombre:string,
    public apellidos:string,
    public edad:number,
    public direccion:string,
    public telefono:string,
    public nacimiento:string,
    public usuario:string,
    public password:string,
    public status:string
  ){
  }
}
