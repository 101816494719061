import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import { GLOBAL } from '../service/global';
import { Router } from '@angular/router';

@Injectable()
export class ConvenioService{
  public url:string;
  public url_servidor = "http://appeuli.orthofam.com.mx/app/cargarArchivo.php";
  public url_servidor2 = "http://appeuli.orthofam.com.mx/app/guardar.php";
  // public url_servidor = "http://localhost:8080/appiconvenio/app/subir.php";

  constructor(
    private _http:HttpClient,
    private _router:Router
  ){
    this.url = GLOBAL.url;
  }

  Auth(){
    //COMPRUEBA SI EXISTE UNA VARIABLE DE SESION
    if(sessionStorage.getItem('sesion') || localStorage.getItem('sesion')){
      return true;
    }else{
      return false;
    }
  }

  getUsuario(){
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.get(this.url + 'usuarios',{headers:headers}).pipe(res=>res);
  }

  //Logeo de usuario
  login(user):Observable<any>{
    let json = JSON.stringify(user);
    let params = 'json='+json;
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url+'login', params,{headers: headers});
  }

  //PETICION DE SERVICIO CON PHP PURO
  cargarArchivo(archivo:File){
    const formData = new FormData();
		formData.append('imagenPropia', archivo, archivo.name);
    return this._http.post(this.url_servidor, formData);

  }
  //Servicio que guarda un archivo
  guardarArchivo(archivo:File){
    const formData = new FormData();
		formData.append('saveImagen', archivo, archivo.name);
    return this._http.post(this.url_servidor2, formData);
  }

  //CARGAR LOS ITEM A LA BASE DE DATOS
  cargar_a_bd(elementos){
    let json = JSON.stringify({'archivo':elementos});
    let params = 'json='+json;
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url+'subir', params,{headers: headers});
  }

  //OBTIENE UN LISTADO
  getListado(){
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.get(this.url + 'listado',{headers:headers});
  }

  //Servicio que obtiene tods los proveedores
  getProveedores(){
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.get(this.url + 'provedores',{headers:headers});
  }

  //Servicio que actualiza el estatus
  updateItem(clave,status){
    let json = JSON.stringify({'clave':clave,'status':status});
    let params = 'json='+json;
    let headers = new HttpHeaders();

    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url+'actualizar', params,{headers: headers});
  }

  //Servicio que obtiene un estatus con base su ID
  getStatusPorId(id){
    let json =JSON.stringify({'id':id});
    let params = 'json='+json;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url+'home/status', params,{headers: headers});
  }

  insertListaPrecios(lista){
    let json =JSON.stringify({lista});
    let params = 'json='+json;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    return this._http.post(this.url+'home/insertarLista', params,{headers: headers});
  }

  //Servicio que obtiene tods los proveedores
  getListaPrecios(){
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    return this._http.get(this.url + 'consulta/lista',{headers:headers});
  }


}
