import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Componentes
import { LoginComponent } from './componentes/login/login.component';
import { HomeComponent } from './componentes/home/home.component';
import { CatalogoComponent } from './componentes/catalogo/catalogo.component';
import { InicioComponent } from './componentes/inicio/inicio.component';
import { ListadoComponent } from './componentes/listado/listado.component';
import { ConsultaComponent } from './componentes/consulta/consulta.component';

//Rutas
const appRoutes:Routes = [
  {path:'', component:LoginComponent},
  {path:'inicio', component:InicioComponent},
  {path:'login', component:LoginComponent},
  {path:'home', component:HomeComponent},
  {path:'catalogo', component:CatalogoComponent},
  {path:'listado', component:ListadoComponent},
  {path:'consulta', component:ConsultaComponent},
  {path:'**', component:LoginComponent}

];

export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes,{ useHash: true});
