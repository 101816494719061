import { Pipe,PipeTransform } from '@angular/core';

@Pipe({
  name:'filtro'
})

export class FiltroPipe implements PipeTransform{

  transform(value:any, arg:any) : any{
    const resultado = [];
    //Recorre el valor que le esta pasando
    for(const post of value){
      //verifica si encuentra el valor
      if(post.Proveedor.indexOf(arg) > -1 ){
        //Lo agrega
        resultado.push(post);
      }else if(post.FechaInicio.indexOf(arg) > -1){
        resultado.push(post);
      }else if(post.FechaFin.indexOf(arg) > -1){
        resultado.push(post);
      }
    }
    return resultado;
  }

}
